export const investData = [
    {
      id: 1,
      ref: 4947,
      bill: "Enterprize Year Subscription",
      issue: "10-05-2019",
      due: "10-13-2019",
      total: "599.00",
      status: "Due",
    },
    {
      id: 2,
      ref: 4904,
      bill: "Maintenance Year Subscription",
      issue: "06-19-2019",
      due: "06-26-2019",
      total: "99.00",
      status: "Paid",
    },
    {
      id: 3,
      ref: 4829,
      bill: "Enterprize Year Subscription",
      issue: "10-04-2018",
      due: "10-12-2018",
      total: "599.00",
      status: "Paid",
    },
    {
      id: 4,
      ref: 4830,
      bill: "Enterprize Anniversary Subscription",
      issue: "12-04-2018",
      due: "14-12-2018",
      total: "399.00",
      status: "Paid",
    },
    {
      id: 5,
      ref: 4840,
      bill: "Enterprize Coverage Subscription",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Cancelled",
    },
    {
      id: 6,
      ref: 4844,
      bill: "	Manual Subscription Adjustments",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Paid",
    },
    {
      id: 7,
      ref: 4847,
      bill: "Automatic Subscription Adjustments",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Paid",
    },
    {
      id: 8,
      ref: 4848,
      bill: "Tiered Subscription",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Paid",
    },
    {
      id: 9,
      ref: 4849,
      bill: "Tiered Subscription",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Paid",
    },
    {
      id: 10,
      ref: 4750,
      bill: "Flexible Subscription Plan",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Paid",
    },
    {
      id: 11,
      ref: 4510,
      bill: "Enterprize Year Subscription",
      issue: "10-05-2019",
      due: "10-13-2019",
      total: "599.00",
      status: "Due",
    },
    {
      id: 12,
      ref: 4520,
      bill: "Maintenance Year Subscription",
      issue: "06-19-2019",
      due: "06-26-2019",
      total: "99.00",
      status: "Paid",
    },
    {
      id: 13,
      ref: 4530,
      bill: "Enterprize Year Subscription",
      issue: "10-04-2018",
      due: "10-12-2018",
      total: "599.00",
      status: "Paid",
    },
    {
      id: 14,
      ref: 4560,
      bill: "Enterprize Anniversary Subscription",
      issue: "12-04-2018",
      due: "14-12-2018",
      total: "399.00",
      status: "Paid",
    },
    {
      id: 15,
      ref: 4570,
      bill: "Enterprize Coverage Subscription",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Cancelled",
    },
    {
      id: 16,
      ref: 4580,
      bill: "	Manual Subscription Adjustments",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Paid",
    },
    {
      id: 17,
      ref: 4590,
      bill: "Automatic Subscription Adjustments",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Paid",
    },
    {
      id: 18,
      ref: 4600,
      bill: "Tiered Subscription",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Paid",
    },
    {
      id: 19,
      ref: 4425,
      bill: "Tiered Subscription",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Paid",
    },
    {
      id: 20,
      ref: 4490,
      bill: "Flexible Subscription Plan",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Paid",
    },
  ];
  
  export const orderData = [
    {
      id: 1,
      transactionId: "#TNX67234",
      referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
      transactionFee: "0.000002",
      paymentMethod: "CoinPayments",
      paymentFrom: "1xA058106537340385c87d264f93",
      paymentTo: "1x0385c87d264A05810653734f93",
      transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
      ref: "YWLX52JG73",
      orderType: "Deposit",
      desc: "Deposited Funds",
      date: "18/10/2019 12:04 PM",
      amountBTC: "0.010201",
      amountUSD: "1290.49",
      balanceBTC: "1.30910201",
      balanceUSD: "101290.49",
      status: "Completed",
    },
    {
      id: 2,
      transactionId: "#TNX67234",
      referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
      transactionFee: "0.000002",
      paymentMethod: "CoinPayments",
      paymentFrom: "1xA058106537340385c87d264f93",
      paymentTo: "1x0385c87d264A05810653734f93",
      transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
      ref: "YWLX52JG74",
      orderType: "Withdrawal",
      desc: "Withdrawal Funds",
      date: "18/10/2019 12:04 PM",
      amountBTC: "0.010201",
      amountUSD: "1290.49",
      balanceBTC: "1.30910201",
      balanceUSD: "101290.49",
      status: "Upcoming",
    },
    {
      id: 3,
      transactionId: "#TNX67234",
      referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
      transactionFee: "0.000002",
      paymentMethod: "CoinPayments",
      paymentFrom: "1xA058106537340385c87d264f93",
      paymentTo: "1x0385c87d264A05810653734f93",
      transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
      ref: "YWLX52JG75",
      orderType: "Profit",
      desc: "Credited Profits",
      date: "18/10/2019 12:04 PM",
      amountBTC: "0.010201",
      amountUSD: "1290.49",
      balanceBTC: "1.30910201",
      balanceUSD: "101290.49",
      status: "Pending",
    },
    {
      id: 4,
      transactionId: "#TNX67234",
      referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
      transactionFee: "0.000002",
      paymentMethod: "CoinPayments",
      paymentFrom: "1xA058106537340385c87d264f93",
      paymentTo: "1x0385c87d264A05810653734f93",
      transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
      ref: "YWLX52JG76",
      orderType: "Withdrawal",
      desc: "Withdrawal Funds",
      date: "18/10/2019 12:04 PM",
      amountBTC: "0.010201",
      amountUSD: "1290.49",
      balanceBTC: "1.30910201",
      balanceUSD: "101290.49",
      status: "Rejected",
    },
    {
      id: 5,
      transactionId: "#TNX67234",
      referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
      transactionFee: "0.000002",
      paymentMethod: "CoinPayments",
      paymentFrom: "1xA058106537340385c87d264f93",
      paymentTo: "1x0385c87d264A05810653734f93",
      transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
      ref: "YWLX52JG77",
      orderType: "Deposit",
      desc: "Deposited Funds",
      date: "18/10/2019 12:04 PM",
      amountBTC: "0.010201",
      amountUSD: "1290.49",
      balanceBTC: "1.30910201",
      balanceUSD: "101290.49",
      status: "Pending",
    },
    {
      id: 6,
      transactionId: "#TNX67234",
      referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
      transactionFee: "0.000002",
      paymentMethod: "CoinPayments",
      paymentFrom: "1xA058106537340385c87d264f93",
      paymentTo: "1x0385c87d264A05810653734f93",
      transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
      ref: "YWLX52JG78",
      orderType: "Withdrawal",
      desc: "Withdrawal Funds",
      date: "18/10/2019 12:04 PM",
      amountBTC: "0.010201",
      amountUSD: "1290.49",
      balanceBTC: "1.30910201",
      balanceUSD: "101290.49",
      status: "Upcoming",
    },
    {
      id: 7,
      transactionId: "#TNX67234",
      referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
      transactionFee: "0.000002",
      paymentMethod: "CoinPayments",
      paymentFrom: "1xA058106537340385c87d264f93",
      paymentTo: "1x0385c87d264A05810653734f93",
      transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
      ref: "YWLX52JG79",
      orderType: "Profit",
      desc: "Credited Profits",
      date: "18/10/2019 12:04 PM",
      amountBTC: "0.010201",
      amountUSD: "1290.49",
      balanceBTC: "1.30910201",
      balanceUSD: "101290.49",
      status: "Pending",
    },
    {
      id: 8,
      transactionId: "#TNX67234",
      referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
      transactionFee: "0.000002",
      paymentMethod: "CoinPayments",
      paymentFrom: "1xA058106537340385c87d264f93",
      paymentTo: "1x0385c87d264A05810653734f93",
      transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
      ref: "YWLX52JG80",
      orderType: "Withdrawal",
      desc: "Withdrawal Funds",
      date: "18/10/2019 12:04 PM",
      amountBTC: "0.010201",
      amountUSD: "1290.49",
      balanceBTC: "1.30910201",
      balanceUSD: "101290.49",
      status: "Rejected",
    },
    {
      id: 9,
      transactionId: "#TNX67234",
      referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
      transactionFee: "0.000002",
      paymentMethod: "CoinPayments",
      paymentFrom: "1xA058106537340385c87d264f93",
      paymentTo: "1x0385c87d264A05810653734f93",
      transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
      ref: "YWLX52JG81",
      orderType: "Deposit",
      desc: "Deposited Funds",
      date: "18/10/2019 12:04 PM",
      amountBTC: "0.010201",
      amountUSD: "1290.49",
      balanceBTC: "1.30910201",
      balanceUSD: "101290.49",
      status: "Pending",
    },
    {
      id: 10,
      transactionId: "#TNX67235",
      referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
      transactionFee: "0.000025",
      paymentMethod: "CoinPayments",
      paymentFrom: "1xA058106537340385c87d264f93",
      paymentTo: "1x0385c87d264A05810653734f93",
      transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
      ref: "YWLX52JG82",
      orderType: "Deposit",
      desc: "Deposited Funds",
      date: "18/10/2019 12:04 PM",
      amountBTC: "0.010201",
      amountUSD: "1290.49",
      balanceBTC: "1.30910201",
      balanceUSD: "101290.49",
      status: "Rejected",
    },
    {
      id: 11,
      transactionId: "#TNX67237",
      referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
      transactionFee: "0.000025",
      paymentMethod: "CoinPayments",
      paymentFrom: "1xA058106537340385c87d264f93",
      paymentTo: "1x0385c87d264A05810653734f93",
      transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
      ref: "YWLX52JG83",
      orderType: "Withdraw",
      desc: "Withdrawal Funds",
      date: "18/10/2019 12:04 PM",
      amountBTC: "0.010201",
      amountUSD: "1290.49",
      balanceBTC: "1.30910201",
      balanceUSD: "101290.49",
      status: "Rejected",
    },
    {
      id: 12,
      transactionId: "#TNX67238",
      referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
      transactionFee: "0.000025",
      paymentMethod: "CoinPayments",
      paymentFrom: "1xA058106537340385c87d264f93",
      paymentTo: "1x0385c87d264A05810653734f93",
      transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
      ref: "YWLX52JG84",
      orderType: "Withdraw",
      desc: "Withdrawal Funds",
      date: "18/10/2019 12:04 PM",
      amountBTC: "0.010201",
      amountUSD: "1290.49",
      balanceBTC: "1.30910201",
      balanceUSD: "101290.49",
      status: "Upcoming",
    },
    {
      id: 13,
      transactionId: "#TNX67239",
      referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
      transactionFee: "0.000025",
      paymentMethod: "CoinPayments",
      paymentFrom: "1xA058106537340385c87d264f93",
      paymentTo: "1x0385c87d264A05810653734f93",
      transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
      ref: "YWLX52JG85",
      orderType: "Profits",
      desc: "Credited Profit",
      date: "18/10/2019 12:04 PM",
      amountBTC: "0.010201",
      amountUSD: "1290.49",
      balanceBTC: "1.30910201",
      balanceUSD: "101290.49",
      status: "Rejected",
    },
    {
      id: 14,
      transactionId: "#TNX67240",
      referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
      transactionFee: "0.000855",
      paymentMethod: "CoinPayments",
      paymentFrom: "1xA058106537340385c87d264f93",
      paymentTo: "1x0385c87d264A05810653734f93",
      transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
      ref: "YWLX52JG86",
      orderType: "Profits",
      desc: "Credited Profit",
      date: "18/10/2019 12:04 PM",
      amountBTC: "0.010201",
      amountUSD: "1290.49",
      balanceBTC: "1.30910201",
      balanceUSD: "101290.49",
      status: "Completed",
    },
    {
      id: 15,
      transactionId: "#TNX67241",
      referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
      transactionFee: "0.000855",
      paymentMethod: "CoinPayments",
      paymentFrom: "1xA058106537340385c87d264f93",
      paymentTo: "1x0385c87d264A05810653734f93",
      transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
      ref: "YWLX52JG87",
      orderType: "Profits",
      desc: "Credited Profit",
      date: "18/10/2019 12:04 PM",
      amountBTC: "0.010201",
      amountUSD: "1290.49",
      balanceBTC: "1.30910201",
      balanceUSD: "101290.49",
      status: "Completed",
    },
    {
      id: 16,
      transactionId: "#TNX67242",
      referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
      transactionFee: "0.000855",
      paymentMethod: "CoinPayments",
      paymentFrom: "1xA058106537340385c87d264f93",
      paymentTo: "1x0385c87d264A05810653734f93",
      transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
      ref: "YWLX52JG88",
      orderType: "Profits",
      desc: "Credited Profit",
      date: "18/10/2019 12:04 PM",
      amountBTC: "0.010201",
      amountUSD: "1290.49",
      balanceBTC: "1.30910201",
      balanceUSD: "101290.49",
      status: "Rejected",
    },
    {
      id: 17,
      transactionId: "#TNX67250",
      referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
      transactionFee: "0.000855",
      paymentMethod: "CoinPayments",
      paymentFrom: "1xA058106537340385c87d264f93",
      paymentTo: "1x0385c87d264A05810653734f93",
      transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
      ref: "YWLX52JG89",
      orderType: "Profits",
      desc: "Credited Profit",
      date: "18/10/2019 12:04 PM",
      amountBTC: "0.010201",
      amountUSD: "1290.49",
      balanceBTC: "1.30910201",
      balanceUSD: "101290.49",
      status: "Upcoming",
    },
    {
      id: 18,
      transactionId: "#TNX67262",
      referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
      transactionFee: "0.000855",
      paymentMethod: "CoinPayments",
      paymentFrom: "1xA058106537340385c87d264f93",
      paymentTo: "1x0385c87d264A05810653734f93",
      transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
      ref: "YWLX52JG90",
      orderType: "Withdrawal",
      desc: "Withdraw funds",
      date: "18/10/2019 12:04 PM",
      amountBTC: "0.010201",
      amountUSD: "1290.49",
      balanceBTC: "1.30910201",
      balanceUSD: "101290.49",
      status: "Pending",
    },
    {
      id: 19,
      transactionId: "#TNX67259",
      referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
      transactionFee: "0.000855",
      paymentMethod: "CoinPayments",
      paymentFrom: "1xA058106537340385c87d264f93",
      paymentTo: "1x0385c87d264A05810653734f93",
      transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
      ref: "YWLX52JG91",
      orderType: "Profits",
      desc: "Credited Profit",
      date: "18/10/2019 12:04 PM",
      amountBTC: "0.010201",
      amountUSD: "1290.49",
      balanceBTC: "1.30910201",
      balanceUSD: "101290.49",
      status: "Upcoming",
    },
    {
      id: 20,
      transactionId: "#TNX67277",
      referenceId: "NIY9TB2JG73YWLXPYM2U8HR",
      transactionFee: "0.000855",
      paymentMethod: "CoinPayments",
      paymentFrom: "1xA058106537340385c87d264f93",
      paymentTo: "1x0385c87d264A05810653734f93",
      transactionHash: "Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93",
      ref: "YWLX52JG93",
      orderType: "Profits",
      desc: "Credited Profit",
      date: "18/10/2019 12:04 PM",
      amountBTC: "0.010201",
      amountUSD: "1290.49",
      balanceBTC: "1.30910201",
      balanceUSD: "101290.49",
      status: "Rejected",
    },
  ];
  
  export const statusOptions = [
    { value: "Paid", label: "Paid" },
    { value: "Due", label: "Due" },
    { value: "Canceled", label: "Cancelled" },
  ];
  
  export const cryptoActivityOptions = [
    { value: "Deposit", label: "Deposit" },
    { value: "Buy", label: "Buy Coin" },
    { value: "Sell", label: "Sell Coin" },
    { value: "Transfer", label: "Transfer" },
    { value: "Withdraw", label: "Withdraw" },
  ];
  
  export const filterStatusOptions = [
    { value: "0", label: "Active" },
    { value: "1", label: "Completed" },
    { value: "2", label: "Cancelled" },
    // { value: "Completed", label: "Completed" },
  ];
  
  export const filterCoin = [
    { value: "Bitcoin", label: "Bitcoin" },
    { value: "Etherium", label: "Etherium" },
    { value: "Litecoin", label: "Litecoin" },
  ];
  
  export const filterPaymentmethod = [
    { value: "wallet", label: "Wallet" },
    // { value: "Bank", label: "Bank" },
  ];
  