import React, { useContext, useState } from 'react';
import Content from '../../../layout/content/Content';
import { Block, BlockBetween, BlockContent, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, PreviewCard, RSelect } from '../../../components/Component';
import { Form, Row, Col, Label, Button, Input } from 'reactstrap';
import { useQuill } from 'react-quilljs';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Head from '../../../layout/head/Head';
import axiosInstance from '../../../utils/AxiosInstance';
import { AuthContext } from '../../../context/AuthContext';
import useShowToast from '../../hooks/useShowToast';

export const textField = [
  { value: "inputText", label: "Input Text" },
  { value: "fileUpload", label: "File Upload" },
];

// const PaymentEdit = () => {

//   const { quillRef } = useQuill();

//   return (
//     <>
//     <Head title="Edit Payment Method"></Head>
//       <Content>
//         <BlockHead size="sm">
//           <BlockBetween className="g-3">
//             <BlockContent>
//               <BlockTitle>Edit Payment Method</BlockTitle>
//               <BlockDes className="text-soft">
//                 <p>Managing client payment methods</p>
//               </BlockDes>
//             </BlockContent>
//             <BlockHeadContent>
//               <Link to={`${process.env.PUBLIC_URL}/payment-method`}>
//                 <Button color="light" outline className="bg-white d-none d-sm-inline-flex">
//                   <Icon name="arrow-left"></Icon>
//                   <span>Back</span>
//                 </Button>
//                 <Button color="light" outline className="btn-icon bg-white d-inline-flex d-sm-none">
//                   <Icon name="arrow-left"></Icon>
//                 </Button>
//               </Link>
//             </BlockHeadContent>
//           </BlockBetween>
//         </BlockHead>

//         <Block size="lg">
//           <PreviewCard>
//             <Form className="formClass">
//               <Row className="g-gs">
//                 <Col md="4">
//                   <div className="form-group">
//                     <Label className="form-label" htmlFor="name">
//                       Name
//                     </Label>
//                     <div className="form-control-wrap">
//                       <input
//                         type="text"
//                         id="name"
//                         placeholder='Name'
//                         className="form-control" />
//                     </div>
//                   </div>
//                 </Col>
//                 <Col md="4">
//                   <div className="form-group">
//                     <Label className="form-label" htmlFor="currency">
//                       Currency
//                     </Label>
//                     <div className="form-control-wrap">
//                       <input
//                         type="text"
//                         id="currency"
//                         placeholder='Currency'
//                         className="form-control" />
//                     </div>
//                   </div>
//                 </Col>

//                 <Col md="4">
//                   <Label className="form-label">
//                     Minimum Deposite Amount
//                   </Label>
//                   <div className="form-control-wrap">
//                     <div className="input-group">
//                       <input type="text" className="form-control" placeholder="Minimum Deposite Amount" required="" />
//                       <div className="input-group-append">
//                         <span className="input-group-text" id="basic-addon2">
//                           USD
//                         </span>
//                       </div>
//                     </div>
//                   </div>
//                 </Col>
//                 <Col md="4">
//                   <Label className="form-label">
//                     Maximum Deposite Amount
//                   </Label>
//                   <div className="form-control-wrap">
//                     <div className="input-group">
//                       <input type="text" className="form-control" placeholder="Maximum Deposite Amount" required="" />
//                       <div className="input-group-append">
//                         <span className="input-group-text" id="basic-addon2">
//                           USD
//                         </span>
//                       </div>
//                     </div>
//                   </div>

//                 </Col>
//                 <Col md="4">
//                   <Label className="form-label">
//                     Percentage Charge
//                   </Label>
//                   <div className="form-control-wrap">
//                     <div className="input-group">
//                       <input type="text" className="form-control" placeholder="Percentage Charge" required="" />
//                       <div className="input-group-append">
//                         <span className="input-group-text" id="basic-addon2">
//                           %
//                         </span>
//                       </div>
//                     </div>
//                   </div>
//                 </Col>
//                 <Col md="4">
//                   <div className="form-group">
//                     <Label className="form-label" htmlFor="fixedCharge">
//                       Fixed Charge
//                     </Label>
//                     <div className="form-control-wrap">
//                       <input
//                         type="text"
//                         id="fixedCharge"
//                         placeholder='Fixed Charge'
//                         className="form-control" />
//                     </div>
//                   </div>
//                 </Col>
//                 <Col md="4">
//                   <div className="form-group">
//                     <Label className="form-label" htmlFor="walletAddress">
//                       Wallet Address
//                     </Label>
//                     <div className="form-control-wrap">
//                       <input
//                         type="text"
//                         id="walletAddress"
//                         placeholder='Wallet Address'
//                         className="form-control" />
//                     </div>
//                   </div>
//                 </Col>
//                 <Col md="4">
//                   <div className="form-group">
//                     <Label className="form-label" htmlFor="qrLink">
//                       QR Link
//                     </Label>
//                     <div className="form-control-wrap">
//                       <input
//                         type="text"
//                         id="qrLink"
//                         placeholder='QR Link'
//                         className="form-control" />
//                     </div>
//                   </div>
//                 </Col>

//                 <Col md="12">
//                   <div>
//                     <div ref={quillRef} />
//                   </div>
//                 </Col>


//                 <Col md="9" lg="10">
//                   <Label className="form-label">
//                     Status
//                   </Label>
//                   <div className="form-group">
//                     <div className="custom-control custom-switch">
//                       <input
//                         type="checkbox"
//                         className="custom-control-input"
//                         name="reg-public"
//                         id="site-off"
//                       />
//                       <label className="custom-control-label" htmlFor="site-off">
//                         Activate
//                       </label>
//                     </div>
//                   </div>
//                 </Col>

//                 <Col md="3" lg="2">
//                   <Button color="primary" className="mt-4"><Icon className="me-1" name="plus" />Add New</Button>
//                 </Col>

//                 <Col md="12">
//                   <table className="table table-bordered tableWithdraw">
//                     <tbody>
//                       <tr>
//                         <th scope="row" className='align-middle'>Order ID</th>
//                         <td>
//                           <Col>
//                             <div className="form-group">
//                               <RSelect options={textField} />
//                             </div>
//                           </Col>
//                         </td>
//                         <td>
//                           <Button color="danger"><Icon name="trash" /></Button>
//                         </td>
//                       </tr>
//                       <tr>
//                         <th scope="row" className='align-middle'>Email Address</th>
//                         <td>
//                           <Col>
//                             <div className="form-group">
//                               <RSelect options={textField} />
//                             </div>
//                           </Col>
//                         </td>
//                         <td>
//                           <Button color="danger"><Icon name="trash" /></Button>
//                         </td>
//                       </tr>
//                       <tr>
//                         <th scope="row" className='align-middle'>Payment Screenshot</th>
//                         <td>
//                           <Col>
//                             <div className="form-group">
//                               <RSelect options={textField} />
//                             </div>
//                           </Col>
//                         </td>
//                         <td>
//                           <Button color="danger"><Icon name="trash" /></Button>
//                         </td>
//                       </tr>
//                     </tbody>
//                   </table>
//                 </Col>

//                 <div className="form-group mt-5 d-flex justify-content-end">
//                   <Button color="light" className="me-3" size="md">
//                     Cancel
//                   </Button>
//                   <Button color="primary" size="md">
//                     Save Changes
//                   </Button>
//                 </div>

//               </Row>
//             </Form>
//           </PreviewCard>
//         </Block>

//       </Content>
//     </>
//   )
// }

// export default PaymentEdit;



const PaymentEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {method} = location.state || {};
  const { adminInfo } = useContext(AuthContext);

  const [paymentMethod, setPaymentMethod] = useState({
    paymentMethodName: method.paymentMethodName || "",
    minAmount: method.minAmount || 0,
    maxAmount: method.maxAmount || 0,
    percentageCharge: method.percentageCharge || 0,
    walletAddress: (method.extraDetails && method.extraDetails.walletAddress) || "",
    walletQRCode: (method.extraDetails && method.extraDetails.walletQRCode) || "",
    isActive: method.isActive || false,
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const showToast = useShowToast();

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setPaymentMethod({
      ...paymentMethod,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const payload = {
      paymentMethodName: paymentMethod.paymentMethodName,
      minAmount: Number(paymentMethod.minAmount),
      maxAmount: Number(paymentMethod.maxAmount),
      percentageCharge: Number(paymentMethod.percentageCharge),
      logo: method.logo || "", // Include logo if it exists
      isActive: paymentMethod.isActive,
      extraDetails: {
        walletAddress: paymentMethod.walletAddress,
        walletQRCode: paymentMethod.walletQRCode,
      },
    };

    try {
      await axiosInstance.put(`/admin/payment-method/edit/${method._id}`, payload, {
        headers: {
          Authorization: `Bearer ${adminInfo.token}`, // Include the token in the request
        },
      });
      setLoading(false);
        showToast("Done", "payment method updated!", "success");
      navigate(`${process.env.PUBLIC_URL}/payment-method`);
    } catch (err) {
      setLoading(false);
      showToast("Error", "Failed to update payment method. Please try again.", "warning");
      setError("Failed to update payment method. Please try again.");
    }
  };

  const [defaultFiles, setDefaultFiles] = useState("");

  return (
    <>
      <Head title="Edit Payment Method"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween className="g-3">
            <BlockContent>
              <BlockTitle>Edit Payment Method</BlockTitle>
              <BlockDes className="text-soft">
                <p>Managing client payment methods</p>
              </BlockDes>
            </BlockContent>
            <BlockHeadContent>
              <Button
                color="light"
                outline
                className="bg-white d-none d-sm-inline-flex"
                onClick={() => navigate(`${process.env.PUBLIC_URL}/payment-method`)}
              >
                <Icon name="arrow-left"></Icon>
                <span>Back</span>
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block size="lg">
          <PreviewCard>
            <Form className="formClass" onSubmit={handleSubmit}>
              <Row className="g-gs">
                <Col md="4">
                  <div className="form-group">
                    <Label className="form-label" htmlFor="paymentMethodName">
                      Payment Method Name
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="paymentMethodName"
                        name="paymentMethodName"
                        placeholder="Name"
                        className="form-control"
                        value={paymentMethod.paymentMethodName}
                        disabled // Disable editing of this field
                      />
                    </div>
                  </div>
                </Col>

                <Col md="4">
                  <Label className="form-label">Minimum Deposit Amount</Label>
                  <div className="form-control-wrap">
                    <div className="input-group">
                      <input
                        type="number"
                        name="minAmount"
                        className="form-control"
                        placeholder="Minimum Deposit Amount"
                        value={paymentMethod.minAmount}
                        onChange={handleInputChange}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon2">
                          USD
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col md="4">
                  <Label className="form-label">Maximum Deposit Amount</Label>
                  <div className="form-control-wrap">
                    <div className="input-group">
                      <input
                        type="number"
                        name="maxAmount"
                        className="form-control"
                        placeholder="Maximum Deposit Amount"
                        value={paymentMethod.maxAmount}
                        onChange={handleInputChange}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon2">
                          USD
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col md="4">
                  <Label className="form-label">Percentage Charge</Label>
                  <div className="form-control-wrap">
                    <div className="input-group">
                      <input
                        type="number"
                        name="percentageCharge"
                        className="form-control"
                        placeholder="Percentage Charge"
                        value={paymentMethod.percentageCharge}
                        onChange={handleInputChange}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon2">
                          %
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col md="4">
                  <div className="form-group">
                    <Label className="form-label" htmlFor="walletAddress">
                      Wallet Address
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="walletAddress"
                        name="walletAddress"
                        placeholder="Wallet Address"
                        className="form-control"
                        value={paymentMethod.walletAddress}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </Col>

                <Col md="4">
                  <div className="form-group">
                    <Label className="form-label" htmlFor="walletQRCode">
                      Wallet QR Code
                    </Label>
                    {/* <div className="form-control-wrap">
                      <input
                        type="text"
                        id="walletQRCode"
                        name="walletQRCode"
                        placeholder="QR Link"
                        className="form-control"
                        value={paymentMethod.walletQRCode}
                        onChange={handleInputChange}
                      />
                    </div> */}
                    <div className="form-control-wrap">
                    <div className="form-file">
                      <Input
                        type="file"
                        id="customFile"
                        onChange={(e) => setDefaultFiles(e.target.files[0].name)}
                      />
                    </div>
                  </div>
                  </div>
                </Col>

                <Col md="4">
                  <Label className="form-label">Status</Label>
                  <div className="form-group">
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="isActive"
                        name="isActive"
                        checked={paymentMethod.isActive}
                        onChange={handleInputChange}
                      />
                      <label className="custom-control-label" htmlFor="isActive">
                        Activate
                      </label>
                    </div>
                  </div>
                </Col>

                <Col md="12">
                  <div className="form-group mt-5 d-flex justify-content-end">
                    <Button
                      color="light"
                      className="me-3"
                      size="md"
                      onClick={() => navigate(`${process.env.PUBLIC_URL}/payment-method`)}
                    >
                      Cancel
                    </Button>
                    <Button color="primary" size="md" type="submit" disabled={loading}>
                      {loading ? "Saving..." : "Save Changes"}
                    </Button>
                  </div>
                </Col>

                {error && (
                  <Col md="12">
                    <div className="alert alert-danger mt-3">{error}</div>
                  </Col>
                )}
              </Row>
            </Form>
          </PreviewCard>
        </Block>
      </Content>
    </>
  );
};

export default PaymentEdit;